<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-layout align-center justify-space-between px-2 row>
				<v-flex>
					<v-layout align-center>
						<v-icon color="grey">info</v-icon>
						<v-flex class="grey--text" ml-3>{{ $t('theme.title') }}</v-flex>
					</v-layout>
				</v-flex>
				<w-btn v-show="isAdmin" icon="add" @click="showCreate = true">{{ $t('settings.create_theme') }}</w-btn>
			</v-layout>
		</v-flex>
		<v-flex scroll-y>
			<v-layout>
				<v-list two-line style="width: 100%">
					<!-- Loader -->
					<v-list-tile v-show="loading">
						<v-list-tile-action>
							<v-progress-circular color="primary" indeterminate />
						</v-list-tile-action>
						<v-list-tile-title v-t="'loading_progress'" />
					</v-list-tile>
					<!-- Themes list -->
					<v-list-tile v-for="theme in themes" v-show="!loading" :key="theme.id">
						<!-- Read only -->
						<template v-if="!editedTheme || editedTheme.id != theme.id">
							<v-list-tile-action class="mx-1">
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn v-show="isAdmin" :disabled="!isEditable(theme)" icon v-on="on" @click="editTheme(theme)">
											<v-icon v-show="isEditable(theme)" color="grey">edit</v-icon>
										</v-btn>
									</template>
									<span v-t="'actions.edit'" />
								</v-tooltip>
							</v-list-tile-action>
							<v-list-tile-avatar :color="theme.color"></v-list-tile-avatar>
							<v-list-tile-content>
								<v-list-tile-title>
									<v-layout row justify-start align-center>
										<v-flex xs4 v-text="theme.title" />
										<v-flex v-show="theme.is_default" xs2 row>
											<v-icon color="info" class="mr-2" small>fas fa-lock</v-icon>
											<span v-t="'sa.catalog_themes.default'" class="info--text" />
										</v-flex>
										<v-flex v-show="theme.is_shared || theme.owner_accounting_firm_id || theme.owner_vendor_id" xs2 :offset-xs2="!theme.is_default" row>
											<v-icon :color="theme.is_shared ? 'success' : 'error'" class="mr-2" small>{{
												theme.is_shared ? 'fas fa-share-alt' : 'fas fa-key'
											}}</v-icon>
											<span
												v-t="
													theme.is_shared
														? 'sa.catalog_themes.shared'
														: theme.owner_accounting_firm_id !== null
														? 'sa.catalog_themes.private_accounting_firm'
														: theme.owner_vendor_id !== null
														? 'sa.catalog_themes.private_company'
														: ''
												"
												:class="
													theme.is_shared
														? 'success--text'
														: theme.owner_accounting_firm_id !== null
														? 'error--text'
														: theme.owner_vendor_id !== null
														? 'error--text'
														: ''
												"
											/>
										</v-flex>
										<v-flex>
											<span>{{ $t(theme.permission.title) }}</span>
										</v-flex>
									</v-layout>
								</v-list-tile-title>
							</v-list-tile-content>
							<v-list-tile-action>
								<w-btn-delete v-if="isEditable(theme)" mini @click="deleteTheme(theme)" />
							</v-list-tile-action>
						</template>
						<!-- Edition -->
						<template v-else>
							<v-list-tile-action></v-list-tile-action>
							<v-list-tile-avatar>
								<w-color-picker v-model="copiedTheme.color" size="40px" />
							</v-list-tile-avatar>
							<v-list-tile-content>
								<v-list-tile-title>
									<v-layout row>
										<v-flex>
											<v-text-field
												v-model="copiedTheme.title"
												autofocus
												maxlength="191"
												:label="$t('settings.theme_name')"
												@keyup.enter="updateTheme(copiedTheme)"
												@keyup.esc="cancelUpdate(copiedTheme.id)"
											></v-text-field>
										</v-flex>
										<v-flex>
											<v-checkbox
												v-model="copiedTheme.is_shared"
												color="primary"
												:label="isAccountant ? $t('theme.actions.share_with_company') : $t('theme.actions.share_with_accounting_firm')"
												hide-details
											/>
										</v-flex>
										<v-flex>
											<v-select v-model="copiedTheme.theme_permission_id" :label="$t('theme.acl_title')" :items="aclListMapped"></v-select>
										</v-flex>
									</v-layout>
								</v-list-tile-title>
							</v-list-tile-content>
							<v-list-tile-action>
								<v-layout row>
									<v-btn color="primary" :disabled="!isEditedThemeValid" flat round @click="updateTheme(theme)">{{ $t('actions.save') }}</v-btn>
									<v-btn flat round @click="cancelUpdate(theme.id)">{{ $t('actions.cancel') }}</v-btn>
								</v-layout>
							</v-list-tile-action>
						</template>
					</v-list-tile>
				</v-list>
			</v-layout>
		</v-flex>
		<CustomerThemeCreator
			v-if="showCreate"
			:accounting-firm="accountingFirmSelected"
			:company="company"
			:is-accountant="isAccountant"
			:acl-list="aclListMapped"
			@add-theme="addTheme"
			@closed="showCreate = false"
		/>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'

export default {
	name: 'CustomerDetailsThemes',
	components: {
		CustomerThemeCreator: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/CustomerThemeCreator')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			copiedTheme: null,
			newTheme: {},
			loading: false,
			editedTheme: null,
			showCreate: false,
			themes: [],
			acl_list: []
		}
	},
	computed: {
		...mapState({
			isAccountant: state => state.auth.isAccountant,
			accountingFirmSelected: state => state.accountingFirm.selected,
			isAdmin: state => state.company.userRoles.isAdmin
		}),
		aclListMapped: function () {
			return this.acl_list.map(acl => ({
				text: this.$t(acl.title),
				value: acl.id
			}))
		},
		isEditedThemeValid: function () {
			return (
				!this.copiedTheme ||
				(this.copiedTheme.title &&
					this.copiedTheme.title.trim().length > 0 &&
					(this.copiedTheme.color != this.editedTheme.color ||
						this.copiedTheme.title != this.editedTheme.title ||
						this.copiedTheme.theme_permission_id != this.editedTheme.theme_permission_id ||
						this.copiedTheme.is_shared != this.editedTheme.is_shared) &&
					(this.copiedTheme.id || !this.themes.some(theme => theme.title === this.copiedTheme.title)))
			)
		}
	},
	watch: {
		'company.id': {
			handler: 'getThemes'
		}
	},
	created: function () {
		this.getThemes()
		this.getACLList()
	},
	methods: {
		addTheme: function (theme) {
			this.themes.push(theme)
		},
		cancelUpdate: function (themeId) {
			this.themes.splice(
				this.themes.findIndex(theme => theme.id == themeId),
				1,
				this.copiedTheme
			)
			this.copiedTheme = null
			this.editedTheme = null
		},
		deleteTheme: function (themeToDelete) {
			this.$dialog
				.warning({
					text: this.$t('actions.are_you_sure'),
					title: this.$t('theme.delete', { name: themeToDelete.title }),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(doIt => {
					if (doIt) {
						const themeId = themeToDelete.id
						let result
						if (this.isAccountant) {
							result = this.service.deleteThemeForAccountant(this.accountingFirmId, this.company.id, themeId)
						} else {
							result = this.service.deleteThemeForCompanyUser(this.company.id, themeId)
						}
						result
							.then(() => {
								this.themes = this.themes.filter(theme => theme.id != themeId)
							})
							.then(this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('settings.messages.theme_deleted')))
					}
				})
		},
		getThemes: function () {
			this.loading = true
			let result = Promise.resolve()
			if (this.accountingFirmId && this.company?.id) {
				if (this.isAccountant) {
					result = this.service.getThemesForAccountant(this.accountingFirmId, this.company.id)
				} else {
					result = this.service.getThemesForCompanyUser(this.company.id)
				}
			}
			return result
				.then(themes => {
					this.themes.clear()
					this.themes = [...themes]
				})
				.finally(() => {
					this.loading = false
				})
		},
		getACLList: function () {
			this.service.getACLList().then(aclListRes => {
				this.acl_list = aclListRes
			})
		},
		isEditable: function (theme) {
			return this.isAdmin && !theme.is_default && ((this.isAccountant && theme.owner_accounting_firm_id) || (!this.isAccountant && theme.owner_vendor_id))
		},
		editTheme: function (theme) {
			this.copiedTheme = { ...theme }
			this.editedTheme = theme
		},
		updateTheme: function (themeToUpdate) {
			let result
			if (this.isAccountant) {
				result = this.service.updateThemeForAccountant(this.accountingFirmId, this.company.id, themeToUpdate.id, this.copiedTheme)
			} else {
				result = this.service.updateThemeForCompanyUser(this.company.id, themeToUpdate.id, this.copiedTheme)
			}
			result
				.then(updatedTheme => {
					this.themes.forEach(theme => {
						if (theme.id == updatedTheme.id) {
							Object.assign(theme, updatedTheme)
						}
					})
				})
				.then(() => { this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('settings.messages.theme_updated'))})
				.finally(() => {
					this.editedTheme = null
					this.copiedTheme = null
				})
		}
	}
}
</script>
